// routes
// import { paths } from 'src/routes/paths';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'Lore',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/lore',
  },
  {
    title: 'Dev Team',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/team',
  },
  {
    title: 'Press',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/press',
  },

];
