// import { updateCacheWithNewRows } from '@mui/x-data-grid/hooks/features/rows/gridRowsUtils';
import axios from 'axios';

// import { createServer } from 'http';
// config
// import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const API_ENDPOINTS = {
  events: '/api/v1/events',
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  galaItems: {
    getGalaItems: '/api/v1/gala/items',
  },
  session: {
    galaLogin: `/api/v1/login/gala/`,
  },
  licenses: {
    lxLicenses: '/api/v1/gala/licenses'
  },
  serverProfiles: {
    getServerProfiles: '/api/v1/server/profile',
    updateServerProfile: '/api/v1/server/profile',
    createServerProfile: '/api/v1/server/profile',
    getServerInventory: '/api/v1/server/inventory',
    getAllInventories: '/api/v1/server/inventory/all',
  },
  serverStore: {
    getStoreOffers: '/api/v1/server/store/offers',
    postServerStoreTransaction: '/api/v1/server/store/transaction',
  },
  bankBalances: {
    getBankBalances: '/api/v1/bank/balance',
  },
};
