import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
// eslint-disable-next-line import/no-cycle
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState: any = {
  offers: [],
  serverStoreStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  serverTrxStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'serverItems',
  initialState,
  reducers: {
    // GET STORE ITEMS
    getServerStoreItemsStart(state) {
      state.serverStoreStatus.loading = true;
      state.serverStoreStatus.empty = false;
      state.serverStoreStatus.error = null;
    },
    getServerStoreItemsFailure(state, action) {
      state.serverStoreStatus.loading = false;
      state.serverStoreStatus.empty = false;
      state.serverStoreStatus.error = action.payload;
    },
    getServerStoreItemsSuccess(state, action) {
      const serverItems = action.payload;

      state.offers = serverItems;

      state.serverStoreStatus.loading = false;
      state.serverStoreStatus.empty = !serverItems.length;
      state.serverStoreStatus.error = null;
    },

     // POST SERVER STORE TRANSACTION
     postServerStoreTransactionStart(state) {
        state.serverTrxStatus.loading = true;
        state.serverTrxStatus.empty = false;
        state.serverTrxStatus.error = null;
      },
      postServerStoreTransactionFailure(state, action) {
        state.serverTrxStatus.loading = false;
        state.serverTrxStatus.empty = false;
        state.serverTrxStatus.error = action.payload;
      },
      postServerStoreTransactionSuccess(state, action) {
        const serverStoreTrx = action.payload;
  
        state.transactions = serverStoreTrx;
  
        state.serverTrxStatus.loading = false;
        state.serverTrxStatus.empty = !serverStoreTrx.length;
        state.serverTrxStatus.error = null;
      },
      resetServerStoreTransactionStatus(state, action) {
        state.serverTrxStatus.loading = false;
        state.serverTrxStatus.empty = true;
        state.serverTrxStatus.error = null;
      }
  },
});

// Reducer
export default slice.reducer;

// Actions 
export const {
 resetServerStoreTransactionStatus
} = slice.actions;

// ----------------------------------------------------------------------

export function getServerStoreItems() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getServerStoreItemsStart());
    try {
      const response = await axios.get(API_ENDPOINTS.serverStore.getStoreOffers);
      // console.log('got store offers', response.data)
      const storeOffers = response.data;
      dispatch(slice.actions.getServerStoreItemsSuccess(storeOffers)); 
    } catch (error) {
      dispatch(slice.actions.getServerStoreItemsFailure(error));
    }
  };
}

export function postServerStoreTransaction({ offer_id, server_profile_name }:{ offer_id: string, server_profile_name: string }) {
    return async (dispatch: Dispatch) => {
      dispatch(slice.actions.postServerStoreTransactionStart());
      try {
        const newPayload = {}
        // const response = await axios.post(`${API_ENDPOINTS.serverStore.postServerStoreTransaction}?offer_id=${offer_id}&server_profile_name=${server_profile_name}`, newPayload)`);
          const response = await axios.post(`${API_ENDPOINTS.serverStore.postServerStoreTransaction}?offer_id=${offer_id}&server_profile_name=${server_profile_name}`, newPayload);
        // console.log('got trx', response.data)
          const trxResponse = response.data;
        dispatch(slice.actions.postServerStoreTransactionSuccess(trxResponse)); 
      } catch (error) {
        dispatch(slice.actions.postServerStoreTransactionFailure(error));
      }
    };
  }