/* eslint-disable */

import React, { useContext } from 'react';
import PopupContext from './PopupContext';
import PopupWindow from './PopupWindow';
import { toQuery } from './utils';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/slices/general';


type SuccessCallback = ({ token }: { token: string; }) => void
type FailureCallback = (error: Error) => void;


interface OAuth2LoginProps {
  id?: string;
  authorizationUrl?: string;
  clientId?: string;
  redirectUri?: string;
  responseType?: string;
  onSuccess?: SuccessCallback;
  onFailure?: FailureCallback;
  buttonText?: string;
  scope?: string;
  state?: string;
  className?: string;
  children?: React.ReactNode;
  popupWidth?: number;
  popupHeight?: number;
  render?: (() => void) | null;
  isCrossOrigin?: boolean;
  extraParams?: Record<string, any>;
  onRequest?: () => void;
  onRedirectCallback?: () => void;
}



const OAuth2Login = ({
   id,
   authorizationUrl,
   clientId,
   redirectUri,
   responseType,
   onSuccess,
   onFailure,
   buttonText = "Login",
   scope = "",
   state = "",
   className = "",
   children = null,
   popupWidth = 680,
   popupHeight = 680,
   render = null,
   isCrossOrigin = false,
   extraParams = {},
   onRequest = () => {},
   onRedirectCallback = ()=>{}
  } : OAuth2LoginProps) => {

  interface OAuth2Payload {
    client_id: string;
    scope: string;
    redirect_uri: string;
    response_type: string;
    state?: string;
  }

  const dispach = useDispatch();

  const onBtnClick = () => {
    const payload: OAuth2Payload = {
      client_id: clientId || '',
      scope,
      redirect_uri: redirectUri || '',
      response_type: responseType || '',
      ...extraParams,
    };

    if (state) {
      payload.state = state;
    }
    dispach(setLoading(true));
    const search = toQuery(payload);
    const width = popupWidth;
    const height = popupHeight;
    const left = window.screenX + ((window.outerWidth - width) / 2);
    const top = window.screenY + ((window.outerHeight - height) / 2.5);

    const popup = PopupWindow.open(
      buttonText,
      `${authorizationUrl}?${search}`,
      { height, width, top, left },
      { locationKey: responseType === "code" ? "search" : "hash", isCrossOrigin }
    );

    onRequest();

    popup
      .then((data: any) => {
        if (!isCrossOrigin && !data.token) {
          throw new Error("'token' not found in received data");
        }
        // @ts-ignore
        onSuccess(data);
      })
      .catch(onFailure);
  };

  if (render) {
    // @ts-ignore
    return render({ className, buttonText, children, onClick: onBtnClick });
  }

  return(
    <PopupContext.Provider value={{ openLoginPopup: onBtnClick}}>{children}</PopupContext.Provider>
      )
  //   <button id={id} className={className} type="button" onClick={onBtnClick}>
  //     {children || buttonText}
  //   </button>

};


export default OAuth2Login;