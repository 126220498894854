import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';

import CompactLayout from 'src/layouts/compact';


// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
export const LorePage = lazy(() => import('src/pages/lore'));
export const MissionReadyPage = lazy(() => import('src/pages/mission-ready'));
export const SetupHelpPage = lazy(() => import('src/pages/setup-help'));
export const TeamPage = lazy(() => import('src/pages/team'));
export const PressPage = lazy(() => import('src/pages/press'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));


// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      { path: 'about-us', element: <AboutPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'faqs', element: <FaqsPage /> },
      { path: 'lore', element: <LorePage /> },
      { path: 'setup-help', element: <SetupHelpPage /> },
      { path: 'characters', element: <MissionReadyPage /> },
      { path: 'team', element: <TeamPage /> },
      { path: 'press', element: <PressPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },

    ],
  },
];
