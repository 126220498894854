import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState: any = {
  galaItems: [],
  galaItemsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'galaItems',
  initialState,
  reducers: {
    // GET GALA ITEMS
    getGalaItemsStart(state) {
      state.galaItemsStatus.loading = true;
      state.galaItemsStatus.empty = false;
      state.galaItemsStatus.error = null;
    },
    getGalaItemsFailure(state, action) {
      state.galaItemsStatus.loading = false;
      state.galaItemsStatus.empty = false;
      state.galaItemsStatus.error = action.payload;
    },
    getGalaItemsSuccess(state, action) {
      const galaItems = action.payload;

      state.galaItems = galaItems;

      state.galaItemsStatus.loading = false;
      state.galaItemsStatus.empty = !galaItems.length;
      state.galaItemsStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions 
// export const {
 
// } = slice.actions;

// ----------------------------------------------------------------------

export function getGalaItems() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getGalaItemsStart());
    try {
      const response = await axios.get(API_ENDPOINTS.galaItems.getGalaItems);
      // console.log('le goks', response.data)
      const tokens = response.data.reduce((acc:any, curr:any)=> acc.concat(curr.tokens), [])
      dispatch(slice.actions.getGalaItemsSuccess(tokens)); // TODO kristian: fix this
    } catch (error) {
      dispatch(slice.actions.getGalaItemsFailure(error));
    }
  };
}