// import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
// import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
import Oauth2LoginView from '../../sections/auth/oauth2/oauth2-login-view';

// ----------------------------------------------------------------------

// // JWT
// const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
// const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

// AUTH0
// const Auth0LoginPage = lazy(() => import('src/pages/auth/auth0/login'));
// const Auth0Callback = lazy(() => import('src/pages/auth/auth0/callback'));

// OAUTH2
// const OAuth2LoginPage = lazy(() => import('src/pages/auth/oauth2/login'));


// // AUTH0
// const GalaLoginPage = lazy(() => import('src/pages/auth/galaAuth/login'));
// const GalaCallback = lazy(() => import('src/pages/auth/galaAuth/callback'));

// ----------------------------------------------------------------------
//
// const authJwt = {
//   path: 'jwt',
//   element: (
//     <GuestGuard>
//       <Outlet />
//     </GuestGuard>
//   ),
//   children: [
//     {
//       path: 'login',
//       element: (
//         <AuthClassicLayout>
//           <JwtLoginPage />
//         </AuthClassicLayout>
//       ),
//     },
//     {
//       path: 'register',
//       element: (
//         <AuthClassicLayout title="Last Expedition">
//           <JwtRegisterPage />
//         </AuthClassicLayout>
//       ),
//     },
//   ],
// };


const authOauth2 = {
  path: 'oauth2',
  element: (
    <GuestGuard>
      <Outlet />
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <Oauth2LoginView />
        </AuthClassicLayout>
      ),
    },
  ],
};

const authAuth0 = {
  path: 'auth0',
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <Oauth2LoginView />
        </AuthClassicLayout>
      ),
    },
  ],
};
//
// const galaAuth = {
//   path: 'galaAuth',
//   children: [
//     {
//       path: 'login',
//       element: (
//         <GuestGuard>
//           <AuthClassicLayout>
//             <GalaLoginPage />
//           </AuthClassicLayout>
//         </GuestGuard>
//       ),
//     },
//     {
//       path: 'callback',
//       element: <GalaCallback />,
//     },
//   ],
// };

export const authRoutes = [
  {
    path: 'auth',
    children: [authOauth2, authAuth0],
  },
];
