import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { UserProfileStore } from 'src/auth/types';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState: UserProfileStore = {
  userProfile: {
    galaId: null,
    lxAccountId: null,
    isNodeOwner: false
  },
  getUserProfileStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    // GET Node Owner Status
    getNodeOwnerStatusStart(state) {
      state.getUserProfileStatus.loading = true;
      state.getUserProfileStatus.empty = false;
      state.getUserProfileStatus.error = null;
    },
    getNodeOwnerStatusFailure(state, action) {
      state.getUserProfileStatus.loading = false;
      state.getUserProfileStatus.empty = false;
      state.getUserProfileStatus.error = action.payload;
    },
    getNodeOwnerStatusSuccess(state, action) {
      const licenses = action.payload;
      const licensesExist =  !!((licenses && licenses.length > 1))
      state.userProfile.isNodeOwner = true || licensesExist
    

      state.getUserProfileStatus.loading = false;
      state.getUserProfileStatus.empty = !licensesExist;
      state.getUserProfileStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions 
// export const {
 
// } = slice.actions;

// ----------------------------------------------------------------------

export function getNodeOwnerStatus() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getNodeOwnerStatusStart());
    try {
      // console.log('Retrieving Last Expedition Node Licenses for user')
      const response = await axios.get(API_ENDPOINTS.licenses.lxLicenses);
      const lxLicenses = response.data
      // console.log(`LX Licenses response = ${JSON.stringify(lxLicenses)}`)
      // console.log('le goks', response.data)
    //   const tokens = response.data.reduce((acc:any, curr:any)=> acc.concat(curr.tokens), [])
      dispatch(slice.actions.getNodeOwnerStatusSuccess(lxLicenses)); // TODO kristian: fix this
    } catch (error) {
      dispatch(slice.actions.getNodeOwnerStatusFailure(error));
    }
  };
}