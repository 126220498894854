import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import galaItems from './slices/gala-items';
import userProfile from './slices/user-profile'
import serverProfiles from './slices/server-profiles';
import serverStore from './slices/server-store';
import bankBalances from './slices/bank-balances';
import general from './slices/general';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['checkout'],
// };

export const rootReducer = combineReducers({
  galaItems,
  userProfile,
  serverProfiles,
  serverStore,
  bankBalances,
  general,
});
