import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
  };
  
  const slice = createSlice({
    name: 'state',
    initialState,
    reducers: {
      setLoading: (state, action) => {
        state.loading = action.payload;
      },
      
    },
  });
  // Reducer
export default slice.reducer;
  
export const { setLoading } = slice.actions;