// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
// import { usePathname } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// _mock
// components
import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Last Expedition',
    children: [
      { name: 'About us', href: paths.about },
      
      // { name: 'FAQs', href: paths.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: 'https://app.gala.games/terms-and-conditions', target: '_blank' },
      { name: 'Privacy Policy', href: 'https://app.gala.games/privacy-policy', target: '_blank' },
    ],
  },
  {
    headline: 'Contact',
    children: [{ name: 'support@gala.games', href: '#' }],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  // const pathname = usePathname();

  // const isHome = pathname === '/';

  // const simpleFooter = (
  //   <Box
  //     component="footer"
  //     sx={{
  //       py: 5,
  //       textAlign: 'center',
  //       position: 'relative',
  //       bgcolor: 'background.default',
  //     }}
  //   >
      
  //     <Container>
  //     <iframe width="100%" height="680px" src="https://www.youtube.com/embed/nQuaApHJ1J0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
  //       <Logo sx={{ mb: 1, mx: 'auto' }} />

  //       <Typography variant="caption" component="div">
  //         © All rights reserved
  //         <br /> Powered by
  //         <Link href="https://gala.games/"> Gala Games </Link>
  //       </Typography>
  //     </Container>
  //   </Box>
  // );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'black',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo sx={{ mb: 3 }} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={3}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              Last Expedition
            </Typography>
            <Button component={RouterLink} href="setup-help" variant="contained" style={{marginTop:20}}>
              
              Need Help?
            </Button>

          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
            
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link:any) => (

                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      target={link.target}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                  
                </Stack>
              ))}
              
            </Stack>
          </Grid>
          
        </Grid>

        <Typography variant="body2" sx={{ mt: 10 }}>
          © Gala Games. All rights reserved
        </Typography>
        
      </Container>
    </Box>
  );

  return mainFooter;
}
