/* eslint-disable */
import React, {useMemo, useCallback, useState, useContext, useEffect} from 'react';
// config
import {OAUTH2_API, PATH_AFTER_LOGIN} from 'src/config-global';
import { AppState } from "@auth0/auth0-react";
import { AuthContext } from './auth-context'
import OAuth2Login from './0auth2login';
import {isValidToken, jwtDecode, setSession} from "../jwt/utils";
import {useSearchParams} from "../../../routes/hook";
import PopupContext from "./PopupContext";
import axios, {API_ENDPOINTS} from "../../../utils/axios";
import axiosInstance from '../../../utils/axios';
import { setLoading } from 'src/redux/slices/general';
import { useDispatch } from 'src/redux/store';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};
// eslint-disable-next-line react/prop-types
const ErrorAlert = ({ error }:any) => (
    <div className="ErrorAlert">
      {/* eslint-disable-next-line react/prop-types */}
      {error && error.message}
    </div>
  )

export function AuthProvider ({ children }: Props): JSX.Element {
  const [token, setToken] = useState<string | null>(null);
  const [device_id, setDeviceId] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const domain = OAUTH2_API.domain ?? '';
  const clientId = OAUTH2_API.clientId ?? '';
  const redirectUri = OAUTH2_API.callbackUrl ?? '';
  const [user, setUser] = useState<any>(null)
  const [status, setStatus] = useState<'loading' | 'authenticated' | 'unauthenticated'>('unauthenticated')

  const onRedirectCallback = useCallback((appState?: AppState) => {
    window.location.replace(appState?.returnTo || window.location.pathname);
  }, []);
  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const { openLoginPopup } = useContext(PopupContext)

  const dispatch = useDispatch();
  

  const handleLoginWithPopup = useCallback(
    async () => {
      try {
        
        await openLoginPopup?.();

        window.location.href = returnTo || PATH_AFTER_LOGIN;
      } catch (error) {
        console.error(error);
        // reset();
        setError(typeof error === 'string' ? error : error.message);
      }
    },
    [openLoginPopup, returnTo]
  );

  const handleLogout = async()=> {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession('');
        setStatus('unauthenticated');
        setToken('');
        setUser(null);
      }
    }catch (e) {
      console.log(e)
    }
  };

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const device_id = sessionStorage.getItem('userUUID')
      const displayName = sessionStorage.getItem('displayName')
      
      setDeviceId(device_id)

      if (accessToken && isValidToken(accessToken)) {
        // const decodedUser = jwtDecode(accessToken);
        
        setSession(accessToken);
        setStatus('authenticated');
        setToken(token);
        setUser({displayName})
      }
    }catch (e) {
      console.log(e)
    }

  }, [status, token]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  type SuccessCallback = ({ token }: { token: string; }) => void
  const onSuccess:SuccessCallback = ({ token }: { token: string }) => {
    sessionStorage.setItem('bro2', token);
    // todo Kristian
    axiosInstance.get(`${API_ENDPOINTS.session.galaLogin}?device_id=${device_id}&token=${token}`)
      .then((response) => {
        // console.log('response', response.data)
        const decodedUser = jwtDecode(token);
        sessionStorage.setItem('bro', response.data.token);
        
        console.log('decodedUser', decodedUser )
        const curr_device_id = sessionStorage.getItem('userUUID')
        if(curr_device_id !== response.data.device_id){
          sessionStorage.setItem('userUUID', response.data.device_id || '')
        }
        sessionStorage.setItem('displayName', decodedUser?.displayName || '')
        setToken(response.data.token);
        setSession(response.data.token);
        setStatus('authenticated');
        
        initialize();
        // setUser(decodedUser)
        // dispatch(setLoading(false));
        window.location.href = PATH_AFTER_LOGIN;
      })
      .catch((error) => {
        console.error(error);
      })
  };

  const handleFail = (error: Error) => {
    setError(error);
    console.error(error);
    dispatch(setLoading(false));
  }

  const memoizedValue = useMemo(
    () => ({
      user,
      token,
      method: 'oauth2',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      loginWithPopup: handleLoginWithPopup,
      logout: handleLogout
    }),
    [handleLoginWithPopup, handleLogout, status, user, token]
  );

  const handleOnRender = ({ className, buttonText, children, onClick }:any) => <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>

  return (
    // @ts-ignore
    <OAuth2Login
      id="lxgalaloginpopup"
      authorizationUrl={process.env.REACT_APP_OAUTH2_DOMAIN}
      clientId={process.env.REACT_APP_OAUTH2_CLIENT_ID}
      redirectUri={process.env.REACT_APP_OAUTH2_CALLBACK_URL}
      responseType="token"
      buttonText="Login"
      onSuccess={onSuccess}
      onFailure={handleFail}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
    </OAuth2Login>
  );
};

export default AuthProvider;


