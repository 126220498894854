import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
// eslint-disable-next-line import/no-cycle
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState: any = {
  currencies: [],
  bankBalancesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'bankBalances',
  initialState,
  reducers: {
    // GET SERVER PROFILES
    getBankBalancesStart(state) {
      state.bankBalancesStatus.loading = true;
      state.bankBalancesStatus.empty = false;
      state.bankBalancesStatus.error = null;
    },
    getBankBalancesFailure(state, action) {
      state.bankBalancesStatus.loading = false;
      state.bankBalancesStatus.empty = false;
      state.bankBalancesStatus.error = action.payload;
    },
    getBankBalancesSuccess(state, action) {
      const bankBalances = action.payload;
      const allowedCurrencies = ["LXItems:id-item-currency-corechunks","LXItems:id-item-currency-fragmentchunks", "LXItems:id-item-currency-minerals"]
      const filteredBalances = bankBalances.filter((balance: any) => allowedCurrencies.includes(balance.currencyId));
      state.currencies = filteredBalances;

      state.bankBalancesStatus.loading = false;
      state.bankBalancesStatus.empty = !bankBalances.length;
      state.bankBalancesStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions 
// export const {
 
// } = slice.actions;

// ----------------------------------------------------------------------

export function getBankBalances() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getBankBalancesStart());
    try {
      const response = await axios.get(API_ENDPOINTS.bankBalances.getBankBalances);
      // console.log('got currencies', response.data)
      const currencies = response.data;
      dispatch(slice.actions.getBankBalancesSuccess(currencies)); 
    } catch (error) {
      dispatch(slice.actions.getBankBalancesFailure(error));
    }
  };
}