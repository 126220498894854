import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState: any = {
  profiles: [],
  inventories: [],
  serverProfilesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  serverAllInventoryStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'serverProfiles',
  initialState,
  reducers: {
    // GET SERVER PROFILES
    getServerProfilesStart(state) {
      state.serverProfilesStatus.loading = true;
      state.serverProfilesStatus.empty = false;
      state.serverProfilesStatus.error = null;
    },
    getServerProfilesFailure(state, action) {
      state.serverProfilesStatus.loading = false;
      state.serverProfilesStatus.empty = false;
      state.serverProfilesStatus.error = action.payload;
    },
    getServerProfilesSuccess(state, action) {
        const serverProfiles = action.payload;

        state.profiles = serverProfiles;

        state.serverProfilesStatus.loading = false;
        state.serverProfilesStatus.empty = !serverProfiles.length;
        state.serverProfilesStatus.error = null;
    },
    getProfileInventorySuccess(state, action) {
        const serverInventory = action.payload;
        
        // Remove the old inventory item
        state.inventories = state.inventories.filter((inventory:any) => inventory.server_profile_name !== serverInventory.server_profile_name);
        
        // Add the new inventory item
        state.inventories = [...state.inventories, serverInventory];
        
        state.serverProfilesStatus.loading = false;
        state.serverProfilesStatus.error = null;
    },
    getAllInventoriesStart(state) {
        state.serverAllInventoryStatus.loading = true;
        state.serverAllInventoryStatus.empty = false;
        state.serverAllInventoryStatus.error = null;
      },
    getAllInventoriesSuccess(state, action) {
        const formatedInventories: any[] = [];
        

        Object.keys(action.payload).forEach((inventory) => {
            formatedInventories.push({
                server_profile_name: inventory,
                inventoryItems: action.payload[inventory]
            });
        });

        // console.log(formatedInventories, 'formatedInventories')

        state.inventories = [...formatedInventories];

        state.serverAllInventoryStatus.loading = false;
        state.serverAllInventoryStatus.error = null;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions 
// export cons ]]
 
// } = slice.actions;

// ----------------------------------------------------------------------

export function getServerProfiles() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getServerProfilesStart());
    try {
      const response = await axios.get(API_ENDPOINTS.serverProfiles.getServerProfiles);
    //   console.log('got profiles', response.data)
      const serverProfiles = response.data;
      dispatch(slice.actions.getServerProfilesSuccess(serverProfiles)); 
    } catch (error) {
      dispatch(slice.actions.getServerProfilesFailure(error));
    }
  };
}

export function getAllProfileInventories() {
    return async (dispatch: Dispatch) => {
      dispatch(slice.actions.getAllInventoriesStart());
      try {
        const response = await axios.get(API_ENDPOINTS.serverProfiles.getAllInventories);
        // console.log('got inventories', response.data)
        
        const serverInventories = response.data;
        dispatch(slice.actions.getAllInventoriesSuccess(serverInventories)); 
      } catch (error) {
        dispatch(slice.actions.getServerProfilesFailure(error));
      }
    };
  }


export function getProfileInventory({server_profile_name}: {server_profile_name: string}) {
    return async (dispatch: Dispatch) => {
      dispatch(slice.actions.getServerProfilesStart());
      try {
        const response: AxiosResponse = await axios.get(`${API_ENDPOINTS.serverProfiles.getServerInventory}?server_profile_name=${server_profile_name}`);
        // if response status code is in 200s then success otherwise throw error
        const {status} = response
        // console.log(`response status = ${status}`)
        if (status >= 400) {
          const errMessage = `Error requesting inventory: ${JSON.stringify(response.data)}`
        //   console.log(errMessage)
          dispatch(slice.actions.getServerProfilesFailure(errMessage));
          return
        }
        // console.log('got inventory', response.data)
        const serverInventory = {server_profile_name, inventoryItems: response.data};
        dispatch(slice.actions.getProfileInventorySuccess(serverInventory)); 
      } catch (error) {
        dispatch(slice.actions.getServerProfilesFailure(error));
      }
    };
  }