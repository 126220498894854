import { useSelector } from 'react-redux';
// @mui
import Box from '@mui/material/Box';

import Tooltip from '@mui/material/Tooltip';
import { fCurrency2 } from 'src/utils/format-number';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

export function normalizeCurrency (currency: { currencyId: string; balance: number;}) {
    const currencyMap: { [key: string]: string } = {
        "LXItems:id-item-currency-corechunks": 'Core Chunks',
        "LXItems:id-item-currency-fragmentchunks" : 'Fragment Chunks',
        "LXItems:id-item-currency-minerals": 'Minerals'
    }
    const imageMap: { [key: string]: string } = {
        "LXItems:id-item-currency-corechunks": '/assets/background/core_fragment_Blue.png',
        "LXItems:id-item-currency-fragmentchunks" : '/assets/background/Fragments_chunk_512X512_green2.png',
        "LXItems:id-item-currency-minerals": '/assets/background/minerals_orange_512.png'
    }

    const swapUrl: { [key: string]: string } = {
        "LXItems:id-item-currency-corechunks": 'https://galaswap.gala.com/swap/GALA%7CUnit%7Cnone%7Cnone/LastExpedition%7CUnit%7CLECoreChunks%7Cnone',
        "LXItems:id-item-currency-fragmentchunks" : 'https://galaswap.gala.com/swap/GALA%7CUnit%7Cnone%7Cnone/LastExpedition%7CUnit%7CLEFragmentChunks%7Cnone',
        "LXItems:id-item-currency-minerals": 'https://galaswap.gala.com/swap/GALA%7CUnit%7Cnone%7Cnone/LastExpedition%7CUnit%7CLEMinerals%7Cnone'
    }
    

    
    
    
    return {
        name: currencyMap[currency.currencyId],
        balance: currency.balance,
        imgUrl: imageMap[currency.currencyId],
        swapUrl: swapUrl[currency.currencyId],
    };
}; 

export default function BalancesDisplay({ light }: { light: boolean }) {

    const currencies = useSelector((state: any) => state.bankBalances.currencies);
    const normalizedCurrencies = currencies.map(normalizeCurrency);
    
    const textColor = light ? '#4a4a4a' : '#fff';
    const size = light ? 24 : 16;
    const fontSize = light ? 18 : 11;
  

    return (
        <Toolbar >
            {normalizedCurrencies.map((currency:any, index:number)=>(
                    <Tooltip title={currency.name} key={index}>
                    <Button
                      variant='text'
                      style={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'flex-start', 
                        alignItems: 'center', 
                        textAlign: 'left', 
                        textTransform:'none', 
                        color: textColor,
                        fontSize,
                      }}
                      color="primary"
                        startIcon={<Box
                          sx={{
                            width: size,
                            height: size,
                            borderRadius: '15%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                      <img
                        alt={`currency-icon-${currency.name}`}
                        src={currency.imgUrl}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectPosition: 'center'
                        }}
                      />
                        </Box>}
                        onClick={() => window.open(currency.swapUrl, '_blank')}
                    >
                      {fCurrency2(currency.balance)}
                    </Button>
                    </Tooltip>
                ))}
        
          </Toolbar>
    );
}