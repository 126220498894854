/* eslint-disable */
// ----------------------------------------------------------------------

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function generateAndStoreUUID() {
  const existingUUID = sessionStorage.getItem('userUUID');
  if (!existingUUID) {
    const newUUID = uuidv4();
    sessionStorage.setItem('userUUID', newUUID);
    return newUUID;
  }
  return existingUUID;
}
