// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const OAUTH2_API = {
  clientId: process.env.REACT_APP_OAUTH2_CLIENT_ID,
  domain: process.env.REACT_APP_OAUTH2_DOMAIN,
  callbackUrl: process.env.REACT_APP_OAUTH2_CALLBACK_URL,
};

export const DOWNLOAD_LINK = process.env.REACT_APP_DOWNLOAD_LINK || paths.page404;


export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
export const SERVER_PROFILES_PATH = paths.dashboard.user.serverProfiles; // as '/dashboard'
