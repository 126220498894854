// import { useState } from 'react';
import { useSelector } from 'react-redux';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, { badgeClasses } from '@mui/material/Badge';
import { DOWNLOAD_LINK } from 'src/config-global';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// routes
import { paths } from 'src/routes/paths';
// components
import Logo from 'src/components/logo';
// import Label from 'src/components/label';
//
// import Oauth2LoginView from 'src/auth/context/oauth2/0auth2login';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';

// SettingsButton , LoginButton
import { AccountPopover, HeaderShadow } from '../_common';
import Oauth2LoginView from '../../pages/auth/oauth2/login'
import { useAuthContext } from '../../auth/hooks';



// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { authenticated } = useAuthContext();

  const loading = useSelector((state:any) => state.general.loading);

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
              opacity: 0.48,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Badge
            sx={{
              [`& .${badgeClasses.badge}`]: {
                top: 8,
                right: -16,
              },
            }}
            badgeContent={
              <Link
                href={paths.comingSoon}
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{ ml: 1 }}
               />
            }
          >
            <Logo />
          </Badge>

          <Box sx={{ flexGrow: 1 }} />

          {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

          <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
            {authenticated ? <AccountPopover /> : null}
            {!isMobile ? <Button variant="contained" style={{marginLeft:10, marginRight:10}} target="" rel="noopener" href={DOWNLOAD_LINK}>
              Download Game
             </Button>
             : null
            }
            {/* @ts-ignore */}
            {loading ? null : <Oauth2LoginView />}

             {/* <SettingsButton */}
             {/* sx={{ */}
             {/*   ml: { xs: 1, md: 0 }, */}
             {/*   mr: { md: 2 }, */}
             {/* }} */}
             {/* /> */}

            {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
