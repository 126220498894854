/* eslint-disable */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import React, {useContext, useCallback, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { paths } from 'src/routes/paths';
import { useSearchParams } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import PopupContext from "../../../auth/context/oauth2/PopupContext";


// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
};

export default function Oauth2LoginView() {
  const { loginWithPopup, authenticated } = useAuthContext();
  const { openLoginPopup } = useContext(PopupContext)
  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const handleLoginWithPopup = useCallback(
    async () => {
      try {
        await loginWithPopup?.();

        window.location.href = returnTo || PATH_AFTER_LOGIN;
      } catch (error) {
        console.error(error);
        // reset();
        setErrorMsg(typeof error === 'string' ? error : error.message);
      }
    },
    [loginWithPopup, returnTo]
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      { authenticated
        ? null
        : <Button component={RouterLink} onClick={openLoginPopup} variant='outlined' sx={{ mr: 1 }}>
          Login
        </Button>
      }
    </Stack>
  );

  return (
    <>
      {renderForm}
    </>
  );
}
