// i18n
import 'src/locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'src/utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// redux
import ReduxProvider from 'src/redux/redux-provider';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/oauth2';
import { useEffect } from 'react';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
import { generateAndStoreUUID, uuidv4 } from 'src/utils/uuidv4';
import axiosInstance, { API_ENDPOINTS } from './utils/axios';
// import { LoadingScreen } from './components/loading-screen';


// ----------------------------------------------------------------------

export default function App() {
  const charAt = `
  ░░          ░░      ░░
  ▒▒          ▒▒▒▒  ▒▒▒▒
  ▒▒              ▒▒    
  ▓▓          ▓▓▓▓  ▓▓▓▓
  ██████████  ██      ██ v0.0.2
  `;
  console.info(`%c${charAt}`, 'color: #5BE49B');

  useScrollToTop();
  
  useEffect(() => {
    const uuid = generateAndStoreUUID();
    const events = {
      "events": [
        {
          "event_version": "0.0.1", 
          "event_id": uuidv4(), 
          "event_type": "player.funnel", 
          "event_name": "web_landing", 
          "event_timestamp": Date.now(), 
          "app_version": "0.0.3", 
          "event_data": {
            "device_id": uuid
          }
        },
      ]
    }
    axiosInstance.post(`${API_ENDPOINTS.events}`, events)
  }, []);
  
  return (
    <ReduxProvider>
    <AuthProvider>
      
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'dark', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'yellow', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red' | 'yellow'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <Router />
                  </AuthConsumer>
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      
    </AuthProvider>
    </ReduxProvider>
  );
}
