
import { LoadingScreen } from 'src/components/loading-screen';

// @mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// theme
//
import Iconify from 'src/components/iconify';
import { useSelector } from 'src/redux/store';
// routes
import { usePathname } from 'src/routes/hook';
//
import Footer from './footer';
import Header from './header';


// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  const pathname = usePathname();

  const isHome = pathname === '/';
  const loading = useSelector((state: any) => state.general.loading);

  

  return (
    
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1, maxWidth:'100%' }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
       { loading ? <LoadingScreen /> : children}
        
      </Box>
      <Box
        sx={{
          position: 'fixed',
          right: 10,
          top: '10%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton onClick={() => window.open('https://twitter.com/Last_Expedition')}><Iconify icon="line-md:twitter-x" width={24} /></IconButton>
        <IconButton onClick={() => window.open('https://www.youtube.com/@LastExpeditionTheGame')}><Iconify icon="line-md:youtube" width={24} /></IconButton>
        {/* <IconButton onClick={() => window.open('https://discord.com')}><Iconify icon="line-md:discord" width={24} /></IconButton> */}
       
      </Box>

      <Footer />
    </Box>
    
  );
}
