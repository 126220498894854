// react
// import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Iconify from 'src/components/iconify';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();
  // const [previousThemeMode, setPreviousThemeMode] = useState('dark');

  // setPreviousThemeMode(settings.themeMode);
  //
  // // Change the theme mode to 'light'
  // useEffect(() => {
  //   if(settings.themeMode !== 'light' && window.location.href.includes('dashboard')){
  //     settings.onUpdate('themeMode', 'light')
  //   }
  //
  //   // Reset the theme mode when the component unmounts
  //   // return () => {
  //   //   settings.onUpdate('themeMode', previousThemeMode)
  //   // };
  // }, [settings, previousThemeMode]);


  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
        <Box
        sx={{
          position: 'fixed',
          right: 10,
          top: '10%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton onClick={() => window.open('https://twitter.com/Last_Expedition')}><Iconify icon="line-md:twitter-x" width={24} /></IconButton>
        <IconButton onClick={() => window.open('https://www.youtube.com/@LastExpeditionTheGame')}><Iconify icon="line-md:youtube" width={24} /></IconButton>
        <IconButton onClick={() => window.open('https://discord.com/channels/692403822265368626/1082373193999859762')}><Iconify icon="line-md:discord" width={24} /></IconButton>
        </Box>
        </Box>
    </>
  );
}
